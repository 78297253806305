import React from 'react';
import {Grid} from "@mui/material";
import { makeStyles } from '@mui/styles';

const buttonStyle = makeStyles(theme => ({
	backgroundSection: {
		height: 300,
		[theme.breakpoints.down('md')]: {
			height: 500,
		},
		[theme.breakpoints.down('xs')]: {
			height: 600,
		},
	}
}));
  
const section1 = () =>{
  	const classButtonStyle = buttonStyle();

	return (
		<Grid 
			id="back-to-top-anchor"
			container item
			justifyContent="center" alignItems="center"
			className={classButtonStyle.backgroundSection}
		>
			<Grid item xs={12} sm={12} md={12} style={{textAlign:"center"}}>
				<h1>MAÎTRISEZ VOTRE MASSE SALARIALE <br/> ET PILOTEZ VOTRE POLITIQUE DE RESSOURCES HUMAINES</h1>
			</Grid>
			<Grid item xs={12} sm={12} md={12} style={{textAlign:"center"}}>
				<h2 style={{textAlign:"center"}}>
					Une solution conçue pour les collectivités locales <br />
					pour faciliter au quotidien la gestion de leurs services
				</h2> 
			</Grid>
		</Grid>         
	)
}

export default section1