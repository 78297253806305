//Import composants utilitaires
import React from "react";

// Import composants material-ui
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Grid } from "@mui/material";

//images card
import ImgPiloter from './serviceImg/piloter.webp';
import ImgAnalyser from './serviceImg/analyser.webp';
import ImgCommuniquer from './serviceImg/communiquer.webp';

const useStyles = makeStyles(theme => ({
    analyse: {
        marginTop: "5vh",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
               "& h3": {
            color: "#f7ad0d", 
            [theme.breakpoints.up('md')]: {
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "right", 
                width: 750, 
                marginLeft: "-425px",            
            },
            [theme.breakpoints.down('md')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto",  
                marginTop: "1vh"
            },
        },
        "& p": {
            [theme.breakpoints.up('md')]: {
                textAlign: "right", 
                fontSize: 18            
            },
            [theme.breakpoints.down('md')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
            },
        },
    },
	pilotage: {
        marginTop: "5vh",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        "& h3": {
            color: "#f7ad0d", 
            [theme.breakpoints.up('md')]: {
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "left", 
                width: 625, 
                marginRight: "-380px", 
                zIndex: 1    
            },
            [theme.breakpoints.down('md')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto", 
                marginTop: "1vh",
                textAlign: "center", 

            },
        },
        "& p": {
            [theme.breakpoints.up('md')]: {
                textAlign: "left", 
                fontSize: 18
            },
            [theme.breakpoints.down('md')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
            },
        }
    },
    communication: {
        marginTop: "5vh",
        [theme.breakpoints.down('md')]: {
            margin: 0,
            "& img":{
                width: "100%",
            }
        },
        [theme.breakpoints.between('sm', 'md')]: {
            margin: 0,
            "& img":{
                width: "100%"
            }
        },
        "& h3": {
            color: "#f7ad0d", 
            [theme.breakpoints.up('md')]: {
                fontSize: 25, 
                fontWeight: "bold", 
                borderBottom: "1px solid #f7ad0d", 
                textAlign: "right", 
                width: 750, 
                marginLeft: "-375px"            
            },
            [theme.breakpoints.down('md')]: {
                border: "none",
                fontSize: 18, 
                margin: 0,
                width: "auto", 
                marginTop: "1vh",
            },
        },
        "& p": {
            [theme.breakpoints.up('md')]: {
                textAlign: "left", 
                fontSize: 18
            },
            [theme.breakpoints.down('md')]: {
                textAlign: "center", 
                fontSize: 16,
                margin: "2vh 0",
            },
        }
    },
}));

export default function ServicesComponent() {
	const classes = useStyles();
    const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('md'));
	const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<>
            <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} id="analyse" className={classes.analyse} style={{padding: responsiveDeviceMobile ? "5px" : 0}}>
                <Grid container item justifyContent={responsiveDeviceMobile ? "center" : "flex-end"} alignItems="center" xs={12} sm={6} md={6} lg={6}>
                    <img alt="icon" src={ImgPiloter} />
                </Grid>
                <Grid container item direction="column" justifyContent={responsiveDeviceMobile ? "center" :"flex-start"} alignItems={responsiveDeviceMobile ? "center" : "flex-start"} xs={12} sm={6} md={6} lg={6}>
                    <h3>Analyser</h3>
                    <Typography>
						Connaissance précise et en temps réel de vos effectifs <br />
						Visualisation des indicateurs RH grâce à des tableaux de bord dynamiques <br />
						Production de diagnostics thématiques <br />
                    </Typography>
                </Grid>  
            </Grid>  
            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} className={classes.pilotage} style={{padding: responsiveDeviceMobile ? "5px" : 0}}>
                {responsiveDeviceMobile ? 
                    <>
                        <Grid container item justifyContent="center" alignItems="center" xs={12}>
                            <img alt="icon" src={ImgAnalyser} />
                        </Grid>
                        <Grid container item direction="column" justifyContent="center" alignItems="center" xs={12}>
                            <h3>Piloter</h3>
                            <Typography>
								Définition d’indicateurs personnalisés et suivi automatisé <br />
								Evaluation de l’impact des mesures nationales sur la masse salariale <br />
								Elaboration de scénarios prospectifs et tests de sensibilité de vos décisions <br />
                            </Typography>
                        </Grid>  
                    </>
                :
                    <>
                        <Grid container direction="column" item justifyContent="flex-start" alignItems="flex-end" xs={6} sm={6} md={6} lg={6} >
							<h3>Piloter</h3>
							<Typography>
								Définition d’indicateurs personnalisés et suivi automatisé <br />
								Evaluation de l’impact des mesures nationales sur la masse salariale <br />
								Elaboration de scénarios prospectifs et tests de sensibilité de vos décisions <br />
                            </Typography>
                        </Grid>  
                        <Grid container item justifyContent="flex-start" alignItems="center" xs={6} sm={6} md={6} lg={6}>
                            <img alt="icon" src={ImgAnalyser}></img>
                        </Grid>
                    </>
                }   
            </Grid>
            <Grid item container xs={12} sm={12} md={12} lg={12} justifyContent="center" spacing={responsiveDeviceDesktop ? 6 : 2} className={classes.communication} style={{padding: responsiveDeviceMobile ? "5px" : 0}}>
                <Grid container item justifyContent={responsiveDeviceMobile ? "center" : "flex-end"} alignItems="center" xs={12} sm={6} md={6} lg={6}> 
                    <img alt="icon" src={ImgCommuniquer} />
                </Grid>
                <Grid container direction="column" item justifyContent={responsiveDeviceMobile ? "center" :"flex-start"} alignItems={responsiveDeviceMobile ? "center" : "flex-start"} xs={12} sm={6} md={6} lg={6}>
                    <h3>Communiquer</h3>
                    <Typography>
						Simplification du reporting mensuel et annuel <br />
						Partage des données RH au sein de votre organisation <br />
						Production rapide de documents de référence de votre politique RH <br />
                    </Typography>
                </Grid>
            </Grid>
        </>


  	);
}
