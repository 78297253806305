import React from 'react';
import Grid from '@mui/material/Grid';
import { useTheme, makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chiffres from '../component/contactImg/fond-chiffre.webp'

const useStyles = makeStyles(theme => ({
	chiffres: {
        backgroundImage: "url(" + Chiffres + ")", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
		marginTop:"6vh",
    },

    anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}));

const EnChiffre = () => { 
	const classes = useStyles();

	const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('md')); 

	return(
		<div className={classes.chiffres}>
			<div className={classes.anchor} id="chiffres"></div>
			<Grid container>  
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<h1 style={{color: "white", fontSize: responsiveDeviceMobile ? 22 : 32, fontWeight: "bold"}}>GS'RH, c'est déjà plus de...</h1>
				</Grid>         
				<Grid item container xs={12} sm={12} md={12} lg={12}>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>103</p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>collectivités <br/> analysées</p>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>51 678 </p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>agents publics intégrés <br/>anonymement</p>
					</Grid>
					<Grid item xs={12} sm={4} md={4} lg={4}> 
						<p style={{color: "#f7ad0d", fontSize: responsiveDeviceMobile ? 40 : 45, fontWeight: "bold", margin: 0}}>916 052</p>
						<p style={{color: "white", fontSize: responsiveDeviceMobile ? 20 : 24, fontWeight: "normal", marginTop: 0}}>bulletins de <br/>paie traités</p>
					</Grid>
				</Grid>         
			</Grid>
		</div>
	)
}

export default EnChiffre