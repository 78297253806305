import React from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';

import Logo from "../component/navigationImg/logo-gsrh.webp"
import { Grid, Button } from "@mui/material";

import PersonIcon from '@mui/icons-material/Person';

const NavStyles = makeStyles(theme => ({
	root: {
        padding: 20
    },
    logo:{
       width: 140,
    },
	itemNav: {
		color: "white !important",
		fontWeight: "bold",
		fontSize: 15, 
		margin: "0px 15px",
		padding: 5,
	}
}))

const StyledButton = styled(Button)({
	background: "#f7ad0d",
	borderRadius: 5,
	color: "white",
	fontSize: 16
});

export default function AppBar() {
    const classe = NavStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<nav>
				<Grid container	direction="row"	className={classe.root}>
					<Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={4} lg={4}>
						<a href='#presentation'><img src={Logo} alt='logo' className={classe.logo}></img></a>
					</Grid>
					<Grid item container xs={8} sm={8} md={8} lg={8}>
						<Grid item container justifyContent="flex-end" alignItems="center" xs={2} sm={2} md={2} lg={2}>
							<Button className={classe.itemNav}  href="#presentation">Présentation</Button>
						</Grid>
						<Grid item container justifyContent="center" alignItems="center" xs={2} sm={2} md={2} lg={2}>
							<Button className={classe.itemNav}  href="#fonctionnalités">Fonctionnalités</Button>
						</Grid>
						<Grid item container justifyContent="flex-start" alignItems="center" xs={3} sm={3} md={3} lg={3}>
							<Button className={classe.itemNav} href="#contact">Qui sommes-nous ?</Button>
						</Grid>
						<Grid item container justifyContent="flex-start" alignItems="center" xs={3} sm={3} md={3} lg={3}>
							<StyledButton startIcon={<PersonIcon style={{ fontSize: 24 }} />} href="https://gsrh.espedata.fr/">Se connecter</StyledButton>
						</Grid> 
					</Grid>
				</Grid>
			</nav>
		</React.Fragment>
	);
}