import React from 'react';
import { makeStyles } from '@mui/styles';

import ServiceComponent from './../component/servicesComponent';
import {Grid} from '@mui/material';

const useStyles = makeStyles(theme => ({
	fonctionnalites: {
		color:'#333', 
		backgroundColor: '#fafafa',
		marginTop: "10vh",
		marginBottom: "10vh",
		[theme.breakpoints.down('xs')]: {
			marginBottom: "5vh",
		},
	},
    anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}));

const Services = () =>{
	const classes = useStyles();

	return (
		<div className={classes.fonctionnalités}>
			<div className={classes.anchor} id="fonctionnalités"></div>
			<Grid item container justifyContent="center" alignItems="center"> 
				<h1 style={{color: "#006388", fontSize: 30, fontWeight: "bold", marginTop: 80}}>Fonctionnalités de l’outil </h1>
				<ServiceComponent />
			</Grid>
		</div>
	)
}
export default Services