import React from 'react';
import './App.css';

import { ThemeProvider, makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import Navigation from './template/navigation.jsx';
import EnChiffre from './template/enChiffre.jsx';
import Partenaires from './template/partenaires.jsx';
import Services from './template/services.jsx';
import Presentation1 from './template/presentation1.jsx';
import Presentation2 from './template/presentation2.jsx';
import Footer from './component/footerComponent.js';

const theme = createTheme();

const useStyles = makeStyles(theme => ({
	root: {
        textAlign: 'center',
		backgroundColor: "white"
    }
}));

const App = () => {
  const classes = useStyles();
	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<div id="content">
					<Navigation />
					<Presentation1 />
					<Services />
					<EnChiffre />
					<Partenaires />
					<Presentation2 />
				</div> 
				<Footer />
			</div>
		</ThemeProvider>
	);
}

export default App;
