import React from 'react';
import { useTheme, makeStyles} from '@mui/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import Plaquette from "../component/section1Img/plaquette_commerciale_2024.pdf"
import BtnConsultez from "../component/section1Img/BtnConsultez.svg"

const useStyles = makeStyles(theme => ({
    presentation: {
		[theme.breakpoints.down('xs')]: {
			marginTop: "3vh"    
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: "5vh"    
		}
	},
    anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}));


const Presentation1 = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsiveDeviceMobile = useMediaQuery(theme.breakpoints.down('md'));
    const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <div className={classes.presentation}>
            <div className={classes.anchor} id="presentation"></div>
            <h1 style={{ color: "#006388", fontSize: responsiveDeviceMobile ? 22 : 30, fontWeight: "bold" }}>Présentation</h1>
            <p style={{ width: responsiveDeviceDesktop ? "75vw" : "85vw", margin: "auto", fontSize: 18 }}>
                GS'RH est un outil qui vous permet de disposer d'une <strong>connaissance précise</strong> de vos effectifs, de votre
                masse salariale et de l'ensemble des données qui traduisent <strong>la politique RH</strong> de votre collectivité.
                Il vous permet d'identifier facilement <strong>vos marges de manoeuvre et de décider des actions pertinentes</strong> concernant la masse salariale, les métiers et effectifs, l'action sociale...<br /><br />
                GS'RH vous permet de suivre mensuellement vos indicateurs RH et de <strong>facilement les partager</strong> (élus,
                comité de direction, DGS, DGA ressources, ...), sans avoir à y consacrer plus de temps.
                Il vous offre la possibilité de réaliser des <strong>exports sur-mesure</strong> de données afin d'alimenter des
                analyses RH ou des études sectorielles et de piloter vos propres <strong>tableaux de bord</strong>.
                Vous disposerez également de <strong>maquettes pré-remplies</strong> pour produire rapidement des documents de
                références indispensables (DOB, égalité professionnelle, lignes directrices de gestion, tableau des
                effectifs, ...).  <br /><br />
            </p>
            <div id="img-container" style={{ margin: 'auto', width: "18rem", height: "auto" }}>
                <a href={Plaquette} style={{ textDecoration: "none", color: "#f7ad0d", fontWeight: "bold", fontSize: 22 }} target="_blank" rel=" noopener noreferrer">
                    <img src={BtnConsultez} alt="notre plaquette commerciale" style={{ width: "100%", height: "100%" }}></img>
                </a>
            </div>
        </div>
    )
}
export default Presentation1
