import React from 'react';
import Nav from '../component/navigationcomponent'
import { makeStyles, useTheme } from '@mui/styles';
import { styled } from "@mui/material/styles";
import Section1 from '../template/section1.jsx';
import Logo from '../component/navigationImg/logo-gsrh.webp'

import PersonIcon from '@mui/icons-material/Person';
import {Button, Grid} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import BackgroundSection1 from '../component/section1Img/bandeau-image.webp'

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: 30,
		position: "relative",
		margin: 0,
		height: 500,
	},
	logo:{
		width: 140,
	},
	itemNav: {
		color: "white",
		fontWeight: "bold",
		fontSize: 20, 
		margin: "0px 15px",
		padding: 5,
	},
	headerLink: {
		color: 'white',
		textTransform: 'uppercase',
		backgroundColor: 'rgba(0 , 69 , 99 , 0.89)',
		width: '100%',
		position: "absolute",
		bottom: 0,
	},
	headerAnchor: {
		zIndex: 1,
		boxShadow: '0 -4px 10px -2px rgba(0,0,0,0.74)',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		textAlign: "center",
	},
		navigation:{  
		backgroundImage: "url(" + BackgroundSection1 + ")", 
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: "white",
    },
	anchor:{
        [theme.breakpoints.between('xs','xl')]: {
			display: 'block',
			position: 'relative',
			top: '-15vh',
			visibility: 'hidden',
        }
    }
}))

const StyledButton = styled(Button)({
	background: "#f7ad0d",
	borderRadius: 5,
	color: "white",
	width: "100%",
	height: "100%",
	textAlign: "center",
	fontSize: 20
});


const Navigation = () =>{
	const classes = useStyles();
	const theme = useTheme();
	const responsiveDeviceDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  
      return (
            <div className={classes.navigation}>
				{responsiveDeviceDesktop ?
				<>
					<Nav/>    
					<Section1 />
				</>
            :
				<Grid container className={classes.root} id="test">
					<Grid item container justifyContent="center" alignItems="center" xs={12}  id="test2">
						<a href='#presentation'><img src={Logo} alt='logo' className={classes.logo}></img></a>
					</Grid>
					<Grid item justifyContent="center"xs={12} sm={12} md={12}> 
						<h1 style={{fontSize: 20, fontWeight: "bold", padding: 10}}>MAÎTRISEZ VOTRE MASSE SALARIALE ET PILOTEZ VOTRE POLITIQUE DE RESSOURCES HUMAINES</h1>
					</Grid>
					<Grid item justifyContent="center"xs={12} sm={12} md={12}>
						<h2 style={{fontSize: 18, fontWeight: "normal", padding: 10, marginBottom: 200}}>
							Une solution conçue pour les collectivités locales
							pour faciliter au quotidien la gestion de leurs services
						</h2> 
					</Grid>
					<Grid container alignContent='center' alignItems='center' className={classes.headerLink}>
						<Grid item xs={6} className={classes.headerAnchor}>Présentation</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Fonctionnalités</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Partenaires</Grid>
						<Grid item xs={6} className={classes.headerAnchor}>Qui sommes-nous</Grid>
						<Grid item xs={12} style={{backgroundColor: "#f7ad0d", fontWeight: "bold"}}>
							<StyledButton startIcon={<PersonIcon style={{ fontSize: 30 }} />} href="https://gsrh.espedata.fr/">Se connecter</StyledButton>
						</Grid>
					</Grid>
				</Grid> 
				}
            </div>
      )
  }
  export default Navigation
  