import React from 'react';
import { makeStyles } from '@mui/styles';
import titrePartenaire from '../component/partenairesImg/titrePartenaire.svg';
import ImgPartenaire from '../component/partenairesImg/partenaires.webp'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: "6vh",
    },
    partenaires: {
		marginTop: "6vh",
		marginBottom: "6vh",
	},
    titre: {
        margin:'auto',
        marginBottom:'3vh',
        height: '2.8rem',
    },
    imgPartenaire: {
        margin:'auto',
        height: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '75%' ,
        },
        [theme.breakpoints.down('md')]: {
            width: '85%' ,
        },
    },
}));

const Partenaires = () =>{
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.titre}>
                <img src={titrePartenaire} alt='ils nous font confiance' height='100%' width="100%"/>
            </div>  
            <div className={classes.imgPartenaire}>
                <img src={ImgPartenaire} height='100%' width="100%" alt='ils nous font confiance'/>
            </div>      
        </div>
    )
}

export default Partenaires;